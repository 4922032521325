import { React, useState } from "react";
import Text from "../../../CommonComponents/text";
import "../home.css";
import { TypeAnimation } from "react-type-animation";
import images from "../../../helpers/ImportImages";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from "@mui/icons-material/Cancel";
import FormComponent from "../../ContactUs/Components/FormComponents";

const Title = () => {
  const [open, setOpen] = useState(false);
  const [mail, setMail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [msg, setMsg] = useState("");

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleClickOpen = () => {
    if (mail !== "" && mail !== null) {
      if (validateEmail(mail) === true) {
        setOpen(true);
      } else {
        setInvalid(true);
        setMsg("The e-mail address entered is invalid.");
      }
    } else {
      setInvalid(true);
      setMsg("The field is required.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listIconStyle = {
    color: "#F4C244",
    cursor: "pointer",
  };

  const scrollToHowItWorks = () => {
    var element = document.getElementById("home-works-container-id");
    var headerOffset = 200;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="home-title-container">
        <div className="home-title-text-container">
          <div>
            <div className="home-title-type-animation">
              <Text
                appearance="bold-40 text-stroke-navy-color"
                color="black"
                text="Same-Day Delivery for"
              />
              <br />
              <TypeAnimation
                sequence={[
                  "Cold-Chain Brands ",
                  2000,
                  "Petcare brands ",
                  2000,
                  "Pharma & Wellness Brands ",
                  2000,
                  "F&B Brands ",
                  2000,
                  "Skincare & Personal Care Brands ",
                  2000,
                  "Electronic Brands ",
                  2000,
                  "Fashion & Apparel Brands ",
                  2000,
                ]}
                wrapper="span"
                cursor={true}
                deletionSpeed={{ type: "keyStrokeDelayInMs", value: 20 }}
                speed={{ type: "keyStrokeDelayInMs", value: 20 }}
                repeat={Infinity}
                style={{
                  display: "inline-block",
                  marginBottom: "10px",
                }}
                className="bold-40 secondary-color"
              />
            </div>
            <div className="home-title-text">
              <Text
                text="Be closer to your customers & deliver faster: boost Sales & Customer Delight through"
                color="black"
                appearance="light-20"
              />
              <Text
                text=" Same-Day Delivery "
                color="secondary-color"
                appearance="bold-20"
              />
              <Text text=" across India." color="black" appearance="light-20" />
            </div>
          </div>

          <div className="textmail-container">
            <div className="business-mail">
              <input
                className="home-email-textf"
                style={
                  invalid
                    ? {
                        border: "solid 2px #dc3232",
                        boxShadow: "4px 4px 0 0 #dc3232",
                      }
                    : null
                }
                type="email"
                placeholder="Enter your business email"
                onChange={(e) => setMail(e.target.value)}
              />
              <div style={{ paddingTop: "7px", marginBottom: "-17px" }}>
                <span className="mail-valid">{invalid ? msg : ""}</span>
              </div>
              <div className="request-access">
                <div className="request-button" onClick={handleClickOpen}>
                  REQUEST ACCESS
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks" onClick={scrollToHowItWorks}>
            <span>HOW IT WORKS</span>
          </div>
        </div>
        <div className="home-india-map">
          <img src={images["IndiaMap.png"]} alt="" />
          <img src={images["MapMarker.png"]} alt="" />
          <img src={images["PlaceName.png"]} alt="" />
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <div id="home-request-access-dialog-container">
          <DialogTitle id="home-request-access-dialog-title-container">
            <Text text="Almost There" appearance="bold-30" color="black" />
            <CancelIcon onClick={handleClose} style={listIconStyle} />
          </DialogTitle>
          <DialogContent>
            {/* {error === "" ? null : <Alert severity="error">{error}</Alert>}
            {sent === true ? (
              <Alert severity="success">
                Thank you for your message. It has been sent.
              </Alert>
            ) : null} */}

            <FormComponent data={mail} />
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default Title;
