import { React, useState } from "react";
import './mobilefooter.css';
import Text from "../../CommonComponents/text";
import images from "../../helpers/ImportImages";
import { Link, useLocation } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaAngellist } from "react-icons/fa6";
import { BsPhoneFill } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { MdEmail } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";



const MobileFooter = () => {
    const location = useLocation();

    return (
        <div>
            <div className="footer">
                <div className="footer-container">
                    <div className="footer-mobi-col">
                        <img src={images["Zippee-Logo.png"]} alt="" />
                        <IconContext.Provider value={{ color: "white", size: "27px" }}>
                            <div className="footer-icons" style={{ marginTop: "10px" }}>
                                <Link
                                    to="https://www.linkedin.com/company/zippee/"
                                    target="_blank"
                                >
                                    <FaLinkedin />
                                </Link>
                                <Link to="https://twitter.com/zippeeindia" target="_blank">
                                    <FaXTwitter />
                                </Link>
                                <Link to="https://wellfound.com/company/zippee" target="_blank">
                                    <FaAngellist />
                                </Link>
                            </div>
                        </IconContext.Provider>
                        <div className="footer-col-3 for_mobi_text">
                            <Text text="Zippee improves customer loyalty & RTOs for India's top E-Commerce brands by enabling Same-Day Delivery through their own websites." />
                            <div style={{ marginTop: "30px" }}>
                                <img src={images["StartupIndialogo.png"]} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="footer-mobi-cell" style={{marginTop:"20px"}}>
            <div className="chile-footer-col-2">
              <div className="footer-header">
                <Text appearance="footer-header-links" text="Company" />
                <Link to="/">
                  <Text appearance="footer-links" text="Home" />
                </Link>
                <Link to="/about">
                  <Text appearance="footer-links" text="About" />
                </Link>
                <Link
                  to="https://zfwindia.notion.site/ZFW-Careers-06c01cf4e6cb48adac0bf98360346805"
                  target="_blank"
                >
                  <Text appearance="footer-links" text="Careers" />
                </Link>
              </div>
              <div className="footer-header">
                <Text appearance="footer-header-links" text="Features" />
                <Link to="/track-order">
                  <Text appearance="footer-links" text="Track Order" />
                </Link>
                <Link to="https://blaze.zippee.delivery/sign-in">
                  <Text appearance="footer-links" text="Blaze" />
                </Link>
              </div>
              <div className="footer-header">
                <Text appearance="footer-header-links" text="Resources" />
                <Link to="/news">
                  <Text appearance="footer-links" text="News" />
                </Link>
                <Link to="/blogs">
                  <Text appearance="footer-links" text="Blogs" />
                </Link>
              </div>
            </div>
          </div>
                    <div className="footer-mobi-col footer-col-3 right_star_align_items">
                        <div>
                            <Text
                                appearance="footer-links"
                                text="Reach us at"
                                styles={{ fontSize: "18px", fontWeight: "bold", color: "#f4c244" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Text
                                appearance="footer-links"
                                text={
                                    <>
                                      <span style={{ display: "flex", alignItems: "center" }}>
                                        <BsPhoneFill style={{ marginRight: "8px" }} />
                                        +91-9650062127
                                      </span>
                                    </>
                                  }
                                styles={{ fontSize: "18px" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "15px" }}>
                            <Text
                                appearance="footer-links"
                                text={
                                    <>
                                      <span style={{ display: "flex", alignItems: "center" }}>
                                        <MdEmail style={{ marginRight: "12px" }} />
                                        hello@zippee.delivery
                                      </span>
                                    </>
                                  }
                                styles={{ fontSize: "18px" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "15px" }}>
                            <Text
                                appearance="footer-links"
                                text={
                                    <>
                                      <span style={{ display: "flex", alignItems: "center" }}>
                                        <SlLocationPin style={{ marginRight: "12px" }} />
                                        345, Udyog Vihar II, Sector 20,
                                      </span>
                                    </>
                                  }
                                styles={{ fontSize: "18px" }}
                            />
                            <Text
                                appearance="footer-links"
                                text="Gurugram, Haryana 122008"
                                styles={{ marginLeft: "29px", fontSize: "18px" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer_mobi_bottom">
                    <div className="footer-col footer-col-2 small-footer-col-4" style={{ padding: "0px" }}>
                        <Text
                            text="© 2024 Zippee. All Rights Reserved."
                            appearance="light-16 footer-links footer-copy-right"
                            styles={{ fontSize: "13px" }}
                        />
                    </div>
                    <div
                        style={{
                            padding: "0px",
                            gap: "6px",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <Link to="/terms-condition">
                            <Text
                                appearance="footer-links"
                                text="Terms & Conditions | "
                                styles={{ fontSize: "12px", marginLeft: "15px"  }}
                            />
                        </Link>
                        <Link to="/privacy-policy">
                            <Text
                                appearance="footer-links"
                                text="Privacy Policy | "
                                styles={{ fontSize: "12px" }}
                            />
                        </Link>
                        <Link to="/refund-policy">
                            <Text
                                appearance="footer-links"
                                text="Refund Policy"
                                styles={{ fontSize: "12px" }}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MobileFooter;
