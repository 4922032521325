import React from "react";
import Text from "../../../CommonComponents/text";
import "../about.css";
import images from "../../../helpers/ImportImages";
import TextField from "../../../CommonComponents/textfield";

const Description = () => {
  return (
    <div className="container">
      <Text text="Meet Zippee" color="black" appearance="bold-40" />
      <div className="description-container">
        <img src={images["MeetZippee.jpg"]} alt="" />
        <div className="description">
          <ul>
            <li>
              {/* <Text text="We enable Same-Day Deliveries for the most customer-centric E-commerce brands through our next-gen fulfillment platform and network of dark stores across India." /> */}
              <TextField color="black" appearance="light-20">
                We enable <b>Same-Day Delivery</b> for the most customer-centric
                E-commerce brands through our{" "}
                <b color="black">
                  next-gen fulfillment platform and network of dark stores{" "}
                </b>
                across India.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-20" color="black">
                Brands connect their online store to our platform in just a few
                clicks & <b>{"can kickstart Same-Day Delivery in < 7 days"}.</b>
              </TextField>
            </li>
            <li>
              <Text
                color="black"
                appearance="light-20"
                text="This approach helps brands significantly improve customer loyalty, fulfillment costs, and RTOs by up to 90%."
              />
            </li>
          </ul>
          <div>
            <TextField color="black" appearance="light-20">
              <b style={{ color: "#E5C244" }}>Our Mission: </b>
              To democratize Amazon-grade logistics for 100,000+ brands in India
              that want to sell more through their own websites, have greater
              control over customers, & offer a comparable Same-Day Delivery
              experience to customers (without worrying about over-dependence on
              marketplaces).
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
