import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import About from "./pages/About/about";
import Resources from "./pages/Resources/resources";
import TrackOrders from "./pages/TrackOrders/track_orders";
import Home from "./pages/Home/home";
import TermAndCondition from "./pages/TermAndCondition/termAndCondition";
import ContactUs from "./pages/ContactUs/ContactUs";
import ScrollToTop from "./Components/scroolToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacyPolicy";
import RefundPolicy from "./pages/RefundPolicy/refundPolicy";
import Navbar from "./Components/NavBar/navbar";
import Footer from "./Components/Footer/footer";
import Blogs from "./pages/Blogs/Blogs";
import ErrorPage from "./pages/404/404";
import GoToTopButton from "./Components/GoToTopButton";
import BlogView from "./pages/Blogs/BlogView";
import ReactGA from "react-ga4";
import MobileFooter from "./Components/Footer/mobileFooter";
import Tickets from "./pages/EmbedTicket/Tickets";

const MEASUREMENT_ID = "G-68X9K7TL1L"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(MEASUREMENT_ID);

function App() {
  
  const isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      {/* <GoToTopButton /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/news" element={<Resources />} />
        <Route path="/track-order" element={<TrackOrders />} />
        <Route path="/terms-condition" element={<TermAndCondition />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<BlogView />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/tickets" element={<Tickets />} />
      </Routes>
      {isMobileDevice ?
      <MobileFooter /> : <Footer />}
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
