import React, { useState, useEffect } from "react";
import Text from "../../CommonComponents/text";
import CustomButton from "../../CommonComponents/CustomButton";
import "./track_orders.css";
import Paper from "@mui/material/Paper";
import { Alert } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const PhoneNumberInput = ({ phoneNumber, setPhoneNumber, isPhoneValid }) => (
  <>
    {!isPhoneValid && (
      <Alert severity="warning">Please enter a valid number</Alert>
    )}
    <input
      type="text"
      placeholder="Mobile Number"
      id="track-orders-input"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
    />
  </>
);

const OTPInput = () => <input type="text" placeholder="Enter OTP" />;

const OrderNumberInput = ({ orderNum, setOrderNum }) => (
  <input
    type="text"
    placeholder="Enter Your Order Number"
    id="track-order-placeholder"
    value = {orderNum}
    onChange={(e) => setOrderNum(e.target.value)}
  />
);

const TrackOrders = () => {
  const [input, setInput] = useState("orderNum");
  const [showIframe, setShowIframe] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [timerStarted, setTimerStarted] = useState(false);
  const [orderNum, setOrderNum] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [msg, setMsg] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let timer = null;

    if (timerStarted && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [seconds, timerStarted]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const orderNumberParam = searchParams.get("orderNumber");
    if (orderNumberParam) {
      setOrderNum(orderNumberParam);
      orderTrack(orderNumberParam)
    }
  }, [location.search]);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const scrollToIframe = () => {
    var element = document.getElementById("orderTrackingFrame");
    var headerOffset = 200;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const orderTrack = (order_num) => {
    if (input === "phone") {
      if (validatePhoneNumber(phoneNumber)) {
        setInput("otp");
        setTimerStarted(true);
      } else {
        setIsPhoneValid(false);
      }
    } else if (input === "otp") {
      setInput("orderNum");
      setPhoneNumber("");
    } else if (input === "orderNum") {
      if (order_num || (orderNum !== "" && orderNum !== null)) {
        let number = orderNum?orderNum:order_num
        setMsg(null);
        setShowIframe(!showIframe);
        setTimeout(() => setShowIframe(true), 500);
        scrollToIframe();
        const queryParams = new URLSearchParams({ orderNumber: number });
        navigate({ search: queryParams.toString() });
      } else {
        setMsg("This field is required.");
      }
    }
  }

  const submit = (event) => {
    event.preventDefault();
    orderTrack();

  };

  const leftSideImagesLink = [
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/CurryIt.png",
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/BurmaBurma.jpeg",
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/Epigamia.png",
  ];

  const rightSideImagesLink = [
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/Haldiram.png",
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/CremeItalia.jpeg",
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/GadreMarine.png",
  ];

  return (
    <div className="track-order-page">
      <div className="track-order-container">
        <div className="track-order-left-images">
          {leftSideImagesLink.map((img_src, index) => (
            <img key={index} src={img_src} alt="" />
          ))}
        </div>
        <div className="track-order-title-container">
          <div className="track-order-title">
            <Text
              color="black"
              text="Track orders from your favourite brands!"
              appearance="bold-40"
            />
            <Text
              color="black"
              text="You may find Order Number/ID in the SMS received from us after Order Confirmation."
            />
          </div>

          <Paper elevation={10} className="track-order-card">
            <form className="track-order-form">
              {input === "phone" && (
                <PhoneNumberInput
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  isPhoneValid={isPhoneValid}
                />
              )}
              {input === "otp" && <OTPInput />}
              {input === "orderNum" && (
                <OrderNumberInput
                  orderNum={orderNum}
                  setOrderNum={setOrderNum}
                />
              )}
              {input === "otp" && (
                <div className="otp-timer">
                  {seconds === 0 ? (
                    <span>Resend OTP</span>
                  ) : (
                    <span>
                      Resend OTP in:&nbsp;
                      <span className="otp-second">{seconds}</span>
                    </span>
                  )}
                </div>
              )}

              {msg ? (
                <span
                  className="validation-error-text"
                  // style={{ margin: "-14px 0 -14px 0" }}
                >
                  {msg}
                </span>
              ) : null}
              <CustomButton
                variant="primary"
                onClick={submit}
                id="track-order-button"
              >
                {input === "phone" ? "SEND OTP" : "SUBMIT"}
              </CustomButton>
            </form>
          </Paper>
        </div>
        <div className="track-order-right-images">
          {rightSideImagesLink.map((img_src, index) => (
            <img key={index} src={img_src} alt="" />
          ))}
        </div>
      </div>
      <div id="orderTrackingFrame">
        {showIframe && (
          <iframe
            className="loginext-iframe"
            src={`https://api.loginextsolutions.com/track/#/order?ordno=${orderNum}&aid=207d993d-5e65-4763-ba46-d04d8e9c9e8d`}
            title="description"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default TrackOrders;
